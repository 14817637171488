import modal from './modal';

class OpenModal {
  constructor(event) {
    this.dataModalOnclick(event);
  }

  dataModalOnclick(event) {

    var beforeOpenFunction = $(event.currentTarget).data('modal-before-open-function');
    if(typeof window[beforeOpenFunction] === 'function') {
      var params = $(event.currentTarget).data('modal-before-open-function-params');
      eval(beforeOpenFunction + '(' + params + ')');
    }

    var afterCallBack = {
      'func': $(event.currentTarget).data('modal-after-open-function'),
      'params': $(event.currentTarget).data('modal-after-open-function-params')
    };

    var linkA = $(event.currentTarget).attr('href');


    if (event.preventDefault) {// non-IE browsers
      event.preventDefault();
    } else { // IE Only
      event.returnValue = false;
    }

    if (!linkA) {
      return;
    }

    this.openDataModalDialog(linkA, afterCallBack);
  }

  openDataModalDialog(url, afterCallBack) {
    $('div.popupModalOverlay').remove();
    $('body').append("<div class='popupModalOverlay'>");

    $.ajax({
      url: encodeURI(url),
      type: 'GET',
      dataType: 'html',
      success: function(html) {
        $('div.popupModalOverlay').html(html);
        modal($('div.modal').attr('id'));
        if (typeof afterCallBack !== 'undefined' && typeof window[afterCallBack.func] === 'function') {
          eval(afterCallBack.func + '(' + afterCallBack.params + ')');
        }
      }
    });
  }
}

export default function(selector) {
  let modals;
  if(typeof selector !== 'undefined') {
    modals = document.querySelectorAll(selector + ' [data-modalup]');
  }else {
    modals = document.querySelectorAll('[data-modalup]');
  }

  [].forEach.call(modals, (modal) => {
    modal.addEventListener('click', (event) => { new OpenModal(event); });
  });
}
