// import '../stylesheets/style.scss';
import calendarElements from './utils/elements';
import './utils/polyfill';
import partsHtml from './utils/parts-html';
import errors from './utils/errors';
import i18n from './utils/i18n';
import Input from './input';
import MODES from './utils/modes';
import dateToString from './utils/date-string';


export default class Calendar {
  constructor(params) {
    this.params = {
      contentElement: null,
      year: this.todayParts.year,
      month: this.todayParts.month,
      dateChanged: () => {},
      dayInput: null,
      allSundays: [],
      allMondays: [],
      lastMonthDay: null,
      visible: false,
      mode: {
        start: true,
        one: false,
        from: true,
        till: false
      },
      selectedDays: [null, null],
    };
    this.params = {
      ...this.params,
      ...params
    };

    this.params.dayInput = new Input(
      this.params.mode,
      this._inputDateChanged
    );

    this.domElements = {
      idElement: +new Date(),
      content: null,
      callCalendarBtn: null,
      containerCalendar: null,
      daysWrapper: null,
      input: null,
      controlsRange: [],
    };

    const checked = this._validateInputParams();
    if (!checked) {
      return;
    }

    this._init();
  }

  _validateInputParams() {
    const {
      contentElement
    } = this.params;

    this.domElements.content = document.querySelector(contentElement);
    if (!contentElement || !this.domElements.content) {
      errors.invalidParams('contentElement');
      errors.invaliDOM('contentElement');
      return false;
    }
    return true;
  }

  _init() {
    this.domElements
      .content
      .insertAdjacentHTML('afterbegin', partsHtml.mainWrapper);


    this.domElements.callCalendarBtn = this.domElements.content
      .querySelector(`.${calendarElements.calendar.calendarInner}`);

    this.params.dayInput.init(this.domElements.content);

    if (!this.domElements.callCalendarBtn) {
      return errors.invaliDOM('Элемент вызова календаря');
    }

    this.domElements
      .callCalendarBtn
      .addEventListener('click', this._callCalendar.bind(this));
  }

  _destroy() {
    this._removeControlsListeners();
    this.domElements.containerCalendar.remove();
    this.params.visible = false;
  }

  _callCalendar() {
    if (this.params.visible) {
      return;
    }
    if (this.params.mode.start) {
      this.params.mode.start = false;
      this.params.mode.from = true;
      this.params.dayInput.renderLayout();
    }
    const eContainer = document.createElement('div');
    eContainer.classList.add(calendarElements.container);
    document.body.appendChild(eContainer);
    eContainer.insertAdjacentHTML('afterbegin', partsHtml.calendar);

    this.domElements.daysWrapper = eContainer
      .querySelector(`.${calendarElements.calendar.daysWrapper}`);
    this.domElements.daysWrapper.style.top = `${this.coordinates.top}px`;
    this.domElements.daysWrapper.style.left = `${this.coordinates.left}px`;

    this.domElements.containerCalendar = eContainer;
    this._renderCalendar();
    this._addControlsListeners();
    this.params.visible = true;
  }

  _inputDateChanged = (date, type) => {
    if (type === MODES.emptyFieldfrom) {
      this.reset(type);
      return;
    }
    if (type === MODES.emptyFieldtill) {
      this.reset(type);
      return;
    }
    if (type === MODES.from) {
      this.params.selectedDays[0] = date;
    }
    if (type === MODES.till) {
      this.params.selectedDays[1] = date;
    }
    this.params.month = new Date(date).getMonth();
    this.params.year = new Date(date).getFullYear();

    if (this.params.visible) {
      this._renderCalendar();
    }
  };

  /**
   * addEventListeners to controls till, from, moths, years
   *
   */
  _addControlsListeners() {
    const eControls = document
      .querySelectorAll(`.${calendarElements.calendar.controls}`);

    if (eControls && eControls.length < 4) {
      return errors.invaliDOM('Контролы переключения дат');
    }

    [].forEach.call(eControls, (c) => {
      if (c.dataset && c.dataset.controlRange) {
        this.domElements.controlsRange.push(c);
        if (this.params.mode[c.dataset.controlRange]) {
          this._toggleButtonRange(c);
        }
      }
      c.addEventListener('click', this._watchControls.bind(this));
    });

    this.domElements
      .containerCalendar
      .addEventListener('click', this._watchContainer.bind(this));

    this.domElements
      .daysWrapper
      .addEventListener('click', this._selectCell.bind(this));

    this.domElements
      .daysWrapper
      .addEventListener('mouseover', this._hoverCell.bind(this));

    this.domElements
      .daysWrapper
      .addEventListener('mouseout', this._refreshCalendar.bind(this));
  }

  _removeControlsListeners() {
    const eControls = document
      .querySelectorAll(`.${calendarElements.calendar.controls}`);


    [].forEach.call(eControls, (c) => {
      c.removeEventListener('click', this._watchControls.bind(this));
    });

    this.domElements
      .containerCalendar
      .removeEventListener('click', this._watchContainer.bind(this));

    this.domElements
      .daysWrapper
      .removeEventListener('click', this._selectCell.bind(this));
  }

  _isEqualDates = (date1, date2) => {
    if (date1 && date2) {
      return date1.toDateString() === date2.toDateString();
    }
    return false;
  };


  _markCellAsSelected = (domRef, day) => {
    if (this._isEqualDates(this.params.selectedDays[0], day.default)) {
      domRef.classList.add(calendarElements.calendar.selected);
    }
    if (this._isEqualDates(this.params.selectedDays[1], day.default)) {
      domRef.classList.add(calendarElements.calendar.selected);
      domRef.classList.add(calendarElements.calendar.selectedTill);
    }
  };

  _markCellAsRangePart_ = (domRef, day, activeMode) => {

    if (activeMode[0] === MODES.one) {
      return;
    }

    const equalsDaysFrom = this.params.selectedDays[0] &&
      this._isEqualDates(day.default, this.params.selectedDays[0]);

    const equalsDaysTill = this.params.selectedDays[1] &&
      this._isEqualDates(day.default, this.params.selectedDays[1]);

    if (activeMode.length > 1) {
      if (this.params.selectedDays[0] && this.params.selectedDays[1]) {
        if (
          (day.default >= this.params.selectedDays[0] || equalsDaysFrom) &&
          (day.default <= this.params.selectedDays[1] || equalsDaysTill)
        ) {
          domRef.classList.add(calendarElements.calendar.range);
          this._fillRangeConfigs(day, domRef);
        }
      }
    } else {
      if (
        this.params.selectedDays[0] &&
        (
          day.default >= this.params.selectedDays[0] ||
          equalsDaysFrom
        )
        ||
        this.params.selectedDays[1] && day.default <= this.params.selectedDays[1]
      ) {
        domRef.classList.add(calendarElements.calendar.range);
        this._fillRangeConfigs(day, domRef);
      }
    }

  };

  _fillRangeConfigs = (day, domRef) => {
    const weekDay = new Date(day.default).getDay();
    const lastDay = new Date(
      this.params.year,
      this.params.month + 1,
      0
    ).getDate();

    if (weekDay === 0 || weekDay === 1) {
      this.params[weekDay === 0 ? 'allSundays' : 'allMondays']
        .push(domRef);
    }
    if (lastDay === new Date(day.default).getDate()) {
      this.params.lastMonthDay = domRef;
    }
  };

  _toggleButtonRange = domRef => {
    domRef
      .classList
      .toggle(calendarElements.calendar.activeControlRange);
  };


  _selectCell({ target }) {
    const { cell, empty, date } = target.dataset;
    if (cell === 'false' || empty === 'true' || !cell) {
      return;
    }
    this._clearHover(`.${calendarElements.calendar.hoverRange}`);

    if (this.activeMode.length > 1) {
      let tmpDate = this.params.selectedDays[0] || this.params.selectedDays[1];
      let bothDate = this.params.selectedDays[0] && this.params.selectedDays[1];
      if (tmpDate) {
        if (new Date(tmpDate) > new Date(date)) {
          this.params.selectedDays[1] = new Date(tmpDate);
          this.params.selectedDays[0] = new Date(date);
        } else {
          this.params.selectedDays[1] = new Date(date);
          this.params.selectedDays[0] = new Date(tmpDate);
        }
      } else {
        this.params.selectedDays[0] = new Date(date);
      }
      if (bothDate) {
        this.params.selectedDays[0] = new Date(date);
        this.params.selectedDays[1] = null;
      }

    } else {
      if (this.params.mode.from || this.params.mode.one) {
        this.params.selectedDays[0] = new Date(date);
        this.params.selectedDays[1] = null;
      }
      if (this.params.mode.till) {
        this.params.selectedDays[0] = null;
        this.params.selectedDays[1] = new Date(date);
      }
    }

    this.params.dayInput.inputValue = this.params.selectedDays;
    this._renderCalendar(true);
  }

  _clearHover(selector) {
    let range = this.domElements.containerCalendar.querySelectorAll(selector);
    if (range) {
      for (let i = 0; i < range.length; i++) {
        range[i].classList.remove(`${calendarElements.calendar.hover}`);
        range[i].classList.remove(`${calendarElements.calendar.hoverRange}`);
        range[i].classList.remove(`${calendarElements.calendar.randeRadius.topStart}`);
        range[i].classList.remove(`${calendarElements.calendar.randeRadius.bottomStart}`);
        range[i].classList.remove(`${calendarElements.calendar.randeRadius.bottomEnd}`);
        range[i].classList.remove(`${calendarElements.calendar.randeRadius.topEnd}`);
      }
    }
  }

  _refreshCalendar({ target }) {
    const {cell, empty, date} = target.dataset;
    if (cell === 'false' || empty === 'true' || !cell) {
      return;
    }

    this._renderCalendar(true);
  }

  _hoverCell({ target }) {
    const {cell, empty, date} = target.dataset;
    if (cell === 'false' || empty === 'true' || !cell) {
      return;
    }

    this._clearHover(`.${calendarElements.calendar.hoverRange}`);

    if(!this.params.mode.one && !target.parentElement.classList.contains(`${calendarElements.calendar.range}`)) {
      target.parentElement.classList.add(calendarElements.calendar.hover);

      let el = this.domElements.containerCalendar.querySelector(`.${calendarElements.calendar.hover}`);
      let row = this.domElements.containerCalendar.querySelector(`.${calendarElements.calendar.hover}`).parentElement;

      if (this.params.mode.from || (this.params.mode.from && this.params.mode.till)) {
        this._setHoverClass(el, true, false);

        while (el.nextElementSibling) {
          if (el.nextElementSibling.firstChild.dataset.empty === 'false') {
            el = this._setHoverClass(el.nextElementSibling);
          } else {
            el = this._setHoverClass(el, false, true);
            break;
          }
        }
        if(el.nextElementSibling === null) {
          this._setHoverClass(el, false, true);
        }

        while (row.nextElementSibling) {
          this._setHoverRow(row.nextElementSibling);
          row = row.nextElementSibling;
        }
      }
      if (!this.params.mode.from && this.params.mode.till) {
        this._setHoverClass(el, false, true);

        while (el.previousElementSibling) {
          if (el.previousElementSibling.firstChild.dataset.empty === 'false') {
            el = this._setHoverClass(el.previousElementSibling);
          } else { break; }
        }
        if(el.previousElementSibling === null || el.previousElementSibling.firstChild.dataset.empty === 'true') {
          el = this._setHoverClass(el, true, false);
        }

        while (row.previousElementSibling) {
          this._setHoverRow(row.previousElementSibling);
          row = row.previousElementSibling;
        }
      }
    }
  }

  _setHoverClass(element, first, last) {
    let item = element;
    if(item.firstChild.dataset.empty === 'false') {
      item.classList.add(calendarElements.calendar.hoverRange);
    }

    if(first) {
      item.classList.add(calendarElements.calendar.randeRadius.topStart);
      item.classList.add(calendarElements.calendar.randeRadius.bottomStart);
    }
    if(last) {
      item.classList.add(calendarElements.calendar.randeRadius.bottomEnd);
      item.classList.add(calendarElements.calendar.randeRadius.topEnd);
    }

    return item;
  }

  _setHoverRow(element) {
    const rowArray = element.querySelectorAll(`.${calendarElements.calendar.cell}`);
    const rowNotEmpty = [];

    $.each(rowArray, function(i, entry) {
      if (entry.firstChild.dataset.empty === 'false') {
        rowNotEmpty.push(entry);
      }
    });

    rowNotEmpty.forEach((item, index) => {
      this._setHoverClass(item, index === 0, index === rowNotEmpty.length - 1);
    });
  }

  _watchContainer({ target, currentTarget }) {
    if (target === currentTarget) {
      this._destroy();
    }
  }

  set currentYear(direction) {
    if (direction === 'next') {
      this.params.year = this.params.year + 1;
    }
    if (direction === 'prev') {
      this.params.year = this.params.year - 1;
    }
  }

  set currentMonth(direction) {
    if (direction === 'next') {
      if (this.params.month === 11) {
        this.params.month = 0;
        this.params.year = this.params.year + 1;
      } else {
        this.params.month = this.params.month + 1;
      }
    }
    if (direction === 'prev') {
      if (this.params.month === 0) {
        this.params.month = 11;
        this.params.year = this.params.year - 1;
      } else {
        this.params.month = this.params.month - 1;
      }
    }
  }

  set currentMode(controlRange) {
    let mode = this.params.mode;
    if (this.activeMode.length > 1) {
      mode[controlRange] = false;
    } else {
      if (mode[controlRange]) {
        mode[controlRange] = !mode[controlRange];
      } else {
        mode[controlRange] = true;
        mode.one = false;
      }
    }


    let result = Object.keys(mode).filter(key => !!mode[key]);

    if (result.length < 1) {
      mode.one = true;
    }

    this.params.mode = mode;
    this.params.dayInput.activeMode = mode;
  }

  get coordinates() {
    return {
      top: this.domElements.content.getBoundingClientRect().bottom + window.pageYOffset + 20,
      left: this.domElements.content.getBoundingClientRect().left + window.pageXOffset
    };
  }

  get todayParts() {
    const today = new Date();
    return {
      year: today.getFullYear(),
      month: today.getMonth(),
      default: today
    };
  }

  get activeMode() {
    return Object
      .keys(this.params.mode)
      .filter(key => !!this.params.mode[key]);
  }

  get containerElement() {
    return this.domElements.content;
  }

  set dateChangeFunction(value) {
    this.params.dateChanged = value;
  }

  _watchControls({ currentTarget }) {
    const {
      controlDirection,
      controlMode,
      controlRange
    } = currentTarget.dataset;

    if (controlMode === 'year') {
      this.currentYear = controlDirection;
    }
    if (controlMode === 'month') {
      this.currentMonth = controlDirection;
    }

    if (controlRange) {
      this.params.selectedDays = [null, null];
      this.currentMode = controlRange;
      this._toggleButtonRange(currentTarget);
    }

    this._renderCalendar(true);
  }


  _renderCalendar(watch) {
    this.params.allMondays = [];
    this.params.allSundays = [];
    this.params.lastMonthDay = null;

    if (!watch) {
      switch (true) {
        case this.params.selectedDays[0] instanceof Object:
          this.params.month = new Date(this.params.selectedDays[0]).getMonth();
          this.params.year = new Date(this.params.selectedDays[0]).getFullYear();
          break;
        case this.params.selectedDays[1] instanceof Object:
          this.params.month = new Date(this.params.selectedDays[1]).getMonth();
          this.params.year = new Date(this.params.selectedDays[1]).getFullYear();
          break;
      }
    }

    const renderDays = this._getDaysInMonth();
    const eAnchor = this.domElements.containerCalendar.querySelector(`.${calendarElements.calendar.anchor}`);
    eAnchor.innerHTML = '';

    const eYear = this.domElements.containerCalendar.querySelector(`.${calendarElements.calendar.year}`);
    const eMonth = this.domElements.containerCalendar.querySelector(`.${calendarElements.calendar.month}`);
    eYear.textContent = this.params.year;
    eMonth.textContent = i18n.months[this.params.month];


    let row = [];
    renderDays.forEach((day, index) => {
      const cell = document.createElement('span');
      cell.classList.add(calendarElements.calendar.cell);
      const cellItem = document.createElement('span');

      cellItem.setAttribute('data-empty', day.empty);
      cellItem.setAttribute('data-date', day.default);
      cellItem.setAttribute('data-today', day.today);
      cellItem.setAttribute('data-cell', 'true');

      if (day.empty) {
        cellItem.innerText = '';
      } else {
        cellItem.innerText = day.number;
        cellItem.classList.add(calendarElements.calendar.cellItem);
      }

      if (day.today) {
        cellItem.classList.add(calendarElements.calendar.today);
      }

      this._markCellAsSelected(cell, day);

      if (!day.empty) {
        this._markCellAsRangePart_(cell, day, this.activeMode);
      }

      cell.appendChild(cellItem);
      row.push(cell);
      if ((index + 1) % 7 === 0) {
        const eRow = document.createElement('div');
        eRow.classList.add('row');
        row.forEach(r => eRow.appendChild(r));
        eAnchor.appendChild(eRow);
        row = [];
      }
    });

    this._renderStartEndRangeDays();
    this._notifyDateChanged();
  }

  _notifyDateChanged = () => {
    if (this.params.selectedDays.length < 1) {
      return;
    }
    let result;

    if (this.params.mode.one) {
      if (this.params.selectedDays[0]) {
        result = dateToString(this.params.selectedDays[0]);
      }
    } else {
      result = this.params.selectedDays
        .map(day => day ? dateToString(day) : null);
    }

    this.params.dateChanged(result);
  };

  _renderStartEndRangeDays = () => {
    const sundays = this.params.allSundays;
    const monday = this.params.allMondays;

    const lengthSunday = sundays.length;
    const lengthMonday = monday.length;

    if (sundays[0]) {
      this._addClassDom(
        sundays[0],
        calendarElements.calendar.randeRadius.topEnd
      );
    }
    if (lengthSunday > 1 && sundays[lengthSunday - 1]) {
      this._addClassDom(
        sundays[lengthSunday - 1],
        calendarElements.calendar.randeRadius.bottomEnd
      );
    }
    if (monday[0]) {
      let className;
      if (lengthMonday === 1) {
        className = calendarElements.calendar.randeRadius.bottomStart;
      } else {
        className = calendarElements.calendar.randeRadius.topStart;
      }
      this._addClassDom(monday[0], className);
    }
    if (lengthMonday > 1 && monday[lengthMonday - 1]) {
      this._addClassDom(
        monday[lengthMonday - 1],
        calendarElements.calendar.randeRadius.bottomStart
      );
    }
    if (this.params.lastMonthDay) {
      this._addClassDom(
        this.params.lastMonthDay,
        calendarElements.calendar.randeRadius.topEnd
      );
      this._addClassDom(
        this.params.lastMonthDay,
        calendarElements.calendar.randeRadius.bottomEnd
      );
    }
  };

  _addClassDom = (ref, className) => {
    ref
      .classList
      .add(className);
  };

  _getDaysInMonth() {
    let date = new Date(this.params.year, this.params.month, 1);
    let days = [];

    while (date.getMonth() === this.params.month) {
      days.push({
        day: new Date(date).getDate(),
        week: new Date(date).getDay(),
        default: new Date(date)
      });
      date.setDate(date.getDate() + 1);
    }
    let before;
    let after;
    if (days[0].week >= 1) {
      before = days[0].week - 1;
    } else {
      before = 6;
    }
    if (days[days.length - 1].week > 1) {
      after = 7 - days[days.length - 1].week;
    } else {
      after = 6;
    }
    const calendar = [];
    let cells = before + days.length + after;
    for (let i = 0; i < cells; i++) {
      const date = new Date(this.params.year, this.params.month, 1 + (i - before));
      let day = {
        default: date,
        empty: date.getMonth() !== this.params.month,
        today: date.toDateString() === this.todayParts.default.toDateString(),
        number: date.getDate()
      };

      calendar.push(day);
    }
    return calendar;
  }


  reset = (type) => {
    switch (true) {
      case type === MODES.emptyFieldfrom : {
        this.params.selectedDays = [null, this.params.selectedDays[1]];
        break;
      }
      case type === MODES.emptyFieldtill : {
        this.params.selectedDays = [this.params.selectedDays[0], null];
        break;
      }
      default:
        this.params.selectedDays = [null, null];
        this.params.mode = {
          start: true,
          one: false,
          from: false,
          till: false
        };
        this.params.year = this.todayParts.year;
        this.params.month = this.todayParts.month;
        this.params.dayInput._removeErrorClass();
        break;
    }


    this.domElements.controlsRange.forEach((control) => {
      if (this.params.mode[control.dataset.controlRange]) {
        control
          .classList
          .add(calendarElements.calendar.activeControlRange);
      } else {
        control
          .classList
          .remove(calendarElements.calendar.activeControlRange);
      }
    });

    if(!type) {
      this.params.dayInput.clearValue(this.params.mode);
    }

    if (this.params.visible) {
      this._renderCalendar();
    } else if(type) {
      this._notifyDateChanged();
    }

  };


  set initialDates(value) {
    const [from, till] = value.dates;
    if (from) {
      const format = this.params.dayInput._formateDateValue(from);
      if (!format.error) {
        this.params.selectedDays[0] = format.defaultDate;
      }
    }
    if (till) {
      const format = this.params.dayInput._formateDateValue(till);
      if (!format.error) {
        this.params.selectedDays[1] = format.defaultDate;
      }
    }
    this.params.mode = value.mode;

    this.params.dayInput.activeMode = this.params.mode;
    this.params.dayInput.inputValue = this.params.selectedDays;
    // this.params.dayInput.renderLayout();
  }

}


