export default function() {
  $(document).on('click', '.cardLayout .toggle-block_head', function() {
    $(this).parent().toggleClass('toggle-block--open');
  });

  $('.card-attachments .inactive-redaction-toggle').change(function(e) {
    $('.no-valid').toggleClass('d-none');
    $('.inactive-data').toggleClass('d-none');
    $('.empty_block').toggleClass('d-none');
    $('.empty-title').toggleClass('d-none');
  });
  $(document).on('click', '.table .table-chevron', function() {
    const parent = $(this).closest('tr');
    $(parent).nextAll().each(function() {
      if ($(this).hasClass('hidden-tr')) {
        $(this).toggleClass('d-none');
      } else {
        return false;
      }
    });
    $(this).toggleClass('table-chevron--down');
  });
}
