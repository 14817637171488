import Calendar from './calendar';

const dateChanged = (datesDefault) => {
  return (dates) => {
    if (typeof dates === 'object') {
      datesDefault.dateFrom.value = dates[0];
      datesDefault.dateTo.value = dates[1];
    } else if (typeof dates === 'string') {
      datesDefault.dateFrom.value = dates;
      datesDefault.dateTo.value = dates;
    } else {
      datesDefault.dateFrom.value = '';
      datesDefault.dateTo.value = '';
    }
    if(typeof datesDefault.dateFrom.onchange === 'function') {
      datesDefault.dateFrom.onchange();
    }
  };
};

export default function() {

  const datePicker = document.querySelectorAll('.datepicker');

  [].forEach.call(datePicker, (element) => {
    let datesDefault = {
      dateFrom:  element.querySelector('.date-from'),
      dateTo: element.querySelector('.date-to')
    };
    const calendar = new Calendar({
      contentElement: `#${element.id}`
    });
    calendar.dateChangeFunction = dateChanged(datesDefault);
    let isDateFrom = datesDefault.dateFrom.value !== null && datesDefault.dateFrom.value !== undefined && datesDefault.dateFrom.value !== '';
    let isDateTo = datesDefault.dateTo.value !== null && datesDefault.dateTo.value !== undefined && datesDefault.dateTo.value !== '';
    let isOneDate = (datesDefault.dateFrom.value === datesDefault.dateTo.value) && isDateFrom && isDateTo;

    if (isDateFrom || isDateTo) {
      calendar.initialDates = {
        dates: [isDateFrom ? datesDefault.dateFrom.value : null, isDateTo ? datesDefault.dateTo.value : null],
        mode: {
          one: isOneDate,
          from: isDateFrom && !isOneDate,
          till: isDateTo && !isOneDate,
        }
      };
    }
    element.addEventListener('resetCalendar', function(e) {
      calendar.reset();
    });
  });
}
