class Tooltip {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.tooltip = document.getElementById('custom-tooltip');
  }

  create() {
    this.wrapper.addEventListener('mouseover', this._mouseOverTooltip.bind(this));
    this.wrapper.addEventListener('mouseout', this._mouseOutTooltip.bind(this));
    this.wrapper.addEventListener('onscroll', this._mouseOutTooltip.bind(this));
    this.wrapper.addEventListener('wheel', this._mouseOutTooltip.bind(this));
  }

  _mouseOverTooltip() {
    this.tooltip.innerHTML = this.wrapper.dataset.tooltip || this.wrapper.dataset.tooltipExtendableGroup || this.wrapper.dataset.tooltipOrgModal;
    // this.tooltip.style.marginLeft = `${this.tooltipText.clientWidth / -2}px`;
    let tooltiptext = this.tooltip.querySelector('.custom-tooltiptext');
    let left = event.pageX - (tooltiptext.clientWidth/2);
    if (left < 10) left = 10;
    let right = left + tooltiptext.clientWidth;
    let clientWidth = document.documentElement.clientWidth;
    if (right > clientWidth) left = clientWidth - tooltiptext.clientWidth-10;
    if (left < 10) left = 10;
    this.tooltip.style.left = `${left}px`;
    this.tooltip.style.top = `${event.clientY + 20}px`;
    tooltiptext.style.opacity = '1';

  }
  _mouseOutTooltip() {
    this.tooltip.innerHTML = '';
    this.tooltip.removeAttribute('style');
  }
}

export default function(selector = '[data-tooltip]') {
  const wrapTooltip = document.querySelectorAll(selector);

  [].forEach.call(wrapTooltip, (wrapper) => {
    const sel = new Tooltip(wrapper);
    sel.create();
  });
}
