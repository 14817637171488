export default function() {
  const tabsLink = document.querySelectorAll('.tabs-nav-custom [data-tab]');
  const tabs = [];
  const activeClass = 'active-tab';
  if (!tabsLink || !tabs) {
    return;
  }

  const getActiveTab = () => {
    return $.grep(tabs, (item) => {
      return item.active === true;
    })[0];
  };
  const getNoActiveTab = () => {
    return $.grep(tabs, (item) => {
      return item.active === false;
    });
  };
  const getTab = (element) => {
    return $.grep(tabs, (item) => {
      return item.link === element;
    })[0];
  };

  const toggleContent = (active, tab) => {
    tab.content.style.display = active ? 'block' : 'none';
    tab.link.setAttribute('data-active', active);
    tab.link.classList.toggle(activeClass);
    tab.active = active;
  };

  const initState = () => {
    if (!getNoActiveTab() || !getActiveTab()) return;

    [].forEach.call(getNoActiveTab(), (item) => {
      item.content.style.display = 'none';
      item.link.classList.remove(activeClass);
    });
    getActiveTab().link.classList.add(activeClass);
  };

  const handleClick = (event) => {
    if (event !== undefined) {
      if (event.currentTarget.dataset.tab) {
        if (!JSON.parse(event.currentTarget.dataset.active)) {
          const activeTab = getActiveTab();
          const clickedTab = getTab(event.currentTarget);

          toggleContent(false, activeTab);
          toggleContent(true, clickedTab);
        }
      }
    }
  };

  [].forEach.call(tabsLink, (tab) => {
    const id = tab.getAttribute('data-tab');
    tab.addEventListener('click', handleClick.bind(this));
    tabs.push({
      link: tab,
      content: document.querySelector(id),
      active: JSON.parse(tab.getAttribute('data-active'))
    });
  });

  initState();
}
