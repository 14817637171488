export default function() {
  if (!$('.btn-on-top')) {
    return;
  }  

  $('.btn-on-top').click(scrollTop.bind(this));

  function scrollTop() {
    $('html,body').animate({ scrollTop: 0 }, 'slow');
  };
}
