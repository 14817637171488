class Dropdown {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.originInput = this.wrapper.querySelector('.dropdown-select');
    this.fakeInput = this.wrapper.querySelector('.dropdown-select_fake');
    this.dropdownInner = this.wrapper.querySelector('.dropdown-inner');
    this.dropdownList = this.wrapper.querySelector('.dropdown-list');
    this.dropdownListItems = this.dropdownList.querySelectorAll('.dropdown-list__item');
    this.dropdownText = this.wrapper.querySelectorAll('.dropdown__text');
    this.svg = this.wrapper.querySelector('svg');
    this.path = this.wrapper.querySelector('path');
    this.imgDropdown = this.wrapper.querySelector('img');
    this.innerHtmlTrue = this.wrapper.querySelector('innerHtmlTrue');
    this.totalSaveSearchTotal = this.wrapper.querySelector('#total-save-search__total');
    this.closeIcon = this.wrapper.querySelector('.close__icon');
    this.defaultItem = this.wrapper.querySelector('.js-item-default');

    this.dropdownListState = this.dropdownList.getAttribute('data-collapse');
    this.selectedItem = null;

    this.status = false;
    if (!this._checkParams()) {
      return;
    }
  }

  _checkParams() {
    if (
      !this.originInput ||
      !this.fakeInput ||
      !this.dropdownInner ||
      !this.dropdownList ||
      !this.dropdownListItems
    ) {
      return false;
    }
    return true;
  }

  _create() {
    this.dropdownInner.addEventListener('click', this._openDropdownList.bind(this));
    if(this.closeIcon) {
      this.closeIcon.style.display = 'none';
      if(this.defaultItem) {
        this.closeIcon.addEventListener('click', this._selectDefaultItem.bind(this));
      }
    }
    this.dropdownList.style.top = `${this.fakeInput.clientHeight + 8}px`;

    [].forEach.call(this.dropdownListItems, (item) => {
      if (item.classList.contains('dropdown-list__item_selected')) {
        this.selectedItem = item;
      }
      if (item.innerText.trim() === this.fakeInput.innerText.trim()) {
        if(this.selectedItem !== null) {
          this.selectedItem.classList.remove('dropdown-list__item_selected');
        }
        this.selectedItem = item;
        item.classList.add('dropdown-list__item_selected');
      }
      item.addEventListener('click', this._selectItem.bind(this));
    });
    this._toggleCloseIcon(null, this.selectedItem !== this.defaultItem);
  }

  _selectItem(event) {
    this.originInput.value = event.target.innerText;
    if($(this.wrapper).find('innerHtmlTrue')) {
      this.fakeInput.innerHTML = event.target.innerHTML;
    }else{
      this.fakeInput.innerHTML = event.target.innerText;
    }
    this._toggleCloseIcon(event, false);


    this.selectedItem.classList.remove('dropdown-list__item_selected');
    $(event.target).closest('.dropdown-list__item').addClass('dropdown-list__item_selected');
    this.selectedItem = event.target;
    this._closeDropdownList();
  }

  _toggleCloseIcon(event, isShow) {
    if (this.closeIcon && this.defaultItem) {
      if ((event && event.target.closest('.dropdown-list__item') !== this.defaultItem) || isShow) {
        this.imgDropdown.closest('.dropdown__text').style.display = 'none';
        this.closeIcon.style.display = 'inline-block';
      } else {
        this.imgDropdown.closest('.dropdown__text').style.display = 'inline-block';
        this.closeIcon.style.display = 'none';
      }
    }
  }

  _openDropdownList() {
    if (this.status === true) {
      this._closeDropdownList();
      return;
    }
    $(this.dropdownList).removeClass('dropdown-list_visible').slideToggle();
    this.status = true;
    this.dropdownList.setAttribute('data-collapse', true);
    if( this.imgDropdown) {
      this.imgDropdown.style.transform = 'scale(1, -1)';
    }
  }

  _closeDropdownList() {
    if(this.dropdownList.getAttribute('data-collapse') === 'true') {
      $(this.dropdownList).addClass('dropdown-list_visible').slideToggle();
      this.dropdownList.setAttribute('data-collapse', false);
      if( this.imgDropdown) {
        this.imgDropdown.style.transform = 'scale(-1, 1)';
      }
      this.status = false;
    }
  }

  _selectDefaultItem(e) {
    this.imgDropdown.closest('.dropdown__text').style.display = 'inline-block';
    this.closeIcon.style.display = 'none';
    this.defaultItem.click();
    e.stopPropagation();
  }
}

export default function(selector) {
  let wrapDropdown = null;
  if(selector) {
    wrapDropdown = document.querySelectorAll(`${selector} .dropdown`);
  }else {
    wrapDropdown = document.querySelectorAll('.dropdown');
  }

  const dropdownOnPage = [];

  if (wrapDropdown) {
    [].forEach.call(wrapDropdown, (dropdown) => {
      const drop = new Dropdown(dropdown);
      drop._create();
      dropdownOnPage.push(drop);
    });
  }

  document.addEventListener('click', (event) => {
    dropdownOnPage.forEach((dropdown) => {
      if (dropdown.status === true && event.target.closest('.dropdown') !== dropdown.wrapper) {
        dropdown._closeDropdownList();
      }
    });
  });
}
