import {btnFloatingMode} from '../lib/btnFloating';
export default function() {

  // Сколько записей показывать на странице
  $(document).on('click', '.search-results:not(:has(section#operators)) .show-by .select-vars__item', function() {
    var changeData = $(this).text();
    $('[name=recordsPerPage]').val($(this).attr('id'));
    if (typeof submitQuickSearchForm == 'function') {
      submitQuickSearchForm(true);
    } else if (typeof submitForm == 'function') {
      submitForm(1, changeData);
    }
  });
  // Блок сортировки
  $('#sort-by-dropdown .dropdown-select_fake').html($('#sort-by-dropdown .dropdown-list__item_selected').html());

  $('#sort-by-dropdown .dropdown-list__item').click(function() {
    $('[name=sortBy]').val($(this).attr('type'));
    if ($(this).hasClass('dropdown-list__item-reverse')) {
      $('[name=sortDirection]').val('true');
    }else {
      $('[name=sortDirection]').val('false');
    }
    submitQuickSearchForm();
  });
  //Сохранение чек-бокса при переходе между страницами
  if($('#showLotsInfoHidden').val() === 'true') {
    $('label[for="checkbox-open-lots"]').click();
  }

  //Пересчёт размеров электронных форм при изменении размера экрана
  let resizeElementsRegBlock = () => {
    let elementsRegBlock = document.querySelectorAll('.search .search-registry-entry-block');
    if (elementsRegBlock.length > 0) {
      Array.prototype.forEach.call(elementsRegBlock, (el,i) => {
        let regEntryForm =el.querySelectorAll('.registry-entry__form')[0];
        regEntryForm.removeAttribute('style');
        let sizeOne = parseFloat(getComputedStyle(regEntryForm, null).height.replace('px', ''));
        let sizeTwo = 0;
        let mtAutoElems = el.querySelectorAll('.registry-entry__right-block .mt-auto');
        if(mtAutoElems.length > 1) {
          mtAutoElems[0].classList.add('d-none');
          mtAutoElems[1].classList.remove('d-none');
          sizeTwo = parseFloat(getComputedStyle(regEntryForm, null).height.replace('px', ''));
          mtAutoElems[0].classList.remove('d-none');
          mtAutoElems[1].classList.add('d-none');
        }
        if(sizeOne > sizeTwo) {
          regEntryForm.style.height = sizeOne+'px';
        }else {
          regEntryForm.style.height = sizeTwo+'px';
        }
      });
    }
  };
  resizeElementsRegBlock();
  (() => {
    let time;
    window.onresize = function(e) {
      if (time)
        clearTimeout(time);
      time = setTimeout(function() {
        resizeElementsRegBlock();
        var btnFloating = document.getElementById('btn-floating');
        if (btnFloating) {
          btnFloating.style.opacity = '1';
        }
      },300);
    };
  })();

  const isExtendedSearchForm = document.querySelector('.extended-search-param');
  const isSearchQuick = document.querySelector('.search-quick');
  if(isSearchQuick) {
    addStyleDataBlock();
  }
  if(isSearchQuick || isExtendedSearchForm) {
    itemForBtnFloating();
    addEventDigitNumber();
  }

}
//Поиск и бинд события на элементы поиска для отображения динамической кнопки применить
function itemForBtnFloating() {
  $(document).on('click', '.removeCustomEl, .removeEl, .listOrg__remove-item', function(e) {
    btnFloatingMode('modal-remove-processing', this, e);
  });

  $(document).on('click', '.filters input[type="checkbox"]', function() {
    btnFloatingMode('checkBox',this);
  });

  const inputDirOrText = document.querySelectorAll('.filters input[type="number"], .filters input[type="dirname"], .filters input[type="text"], .filters input[type="radio"]');
  Array.prototype.forEach.call(inputDirOrText, (el) => {
    el.onchange = function(event) {
      let btnIsPaste = (el.getAttribute('pasted') === '1');
      if (event.type === 'paste') {
        el.setAttribute('pasted', '1');
      }
      if (!btnIsPaste) {
        btnFloatingMode('inputDirOrText',el);
      } else {
        el.setAttribute('pasted', '0');
      }
    };
    el.onpaste = el.onchange;
  });

  const alphanumeric = document.querySelectorAll('.filters input[type="alphanumeric"]');
  Array.prototype.forEach.call(alphanumeric, (el) => {
    el.onchange = function() {
      btnFloatingMode('alphanumeric',el);
    };
  });

  const datepicker = document.querySelectorAll('.filters input.date-from, .filters input.date-to');
  Array.prototype.forEach.call(datepicker, (el) => {
    el.onchange = function() {
      btnFloatingMode('datepicker',el.parentNode);
    };
    // el.addEventListener('change',() => {
    //   btnFloatingMode('datepicker',el.parentNode);
    // });
  });

  const modal = document.querySelectorAll('.filters .search-group .cat-text_small');
  Array.prototype.forEach.call(modal, (el) => {
    el.addEventListener('click', (event) => {
      btnFloatingMode('modal',el, event);
    });
  });

  const deleteClassifiers = document.querySelectorAll('.filters .search-group .deleteClassifiers');
  Array.prototype.forEach.call(deleteClassifiers, (el) => {
    el.addEventListener('click', (event) => {
      btnFloatingMode('deleteClassifiers',el, event);
    });
  });
}

function addStyleDataBlock() {
  const dataList = document.querySelectorAll('.datepicker');
  const parentdataList =[];
  Array.prototype.forEach.call(dataList, (el,i) => {
    const parentData = el.closest('.block');
    if(!parentData.querySelector('.filter-title--small')) {
      parentdataList.push(parentData);
    }
  });
  Array.prototype.forEach.call(parentdataList, (el,i) => {
    el.classList.add('pt-0');
    el.classList.add('border-0');
  });
}

function addEventDigitNumber() {
  const digitNumberList = document.querySelectorAll('.js-digit-number');
  Array.prototype.forEach.call(digitNumberList, (el) => {
    el.addEventListener('keyup',() => {
      setTimeout(function() {
        el.value = getDigitNumber(el.value);
      }, 0);
    });
    if(el.value) {
      setTimeout(function() {
        el.value = getDigitNumber(el.value);
      }, 0);
    }
  });
}
function getDigitNumber(number) {
  let parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}
