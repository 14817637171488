export default function() {
  $(document).on('click', '.submenu-list__item, .main-item:not(:has("ul"))', function() {
    if ($(this).find('a').prop('href') !== '')
      location.href = $(this).find('a').prop('href');
  });

  var timedelay = 0, currentitems;
  $('.main-item').hover(function() {
    clearTimeout(timedelay);
    if ($(this) !== currentitems)
      $(currentitems).find('ul').css('display', 'none');
    currentitems = $(this);
    $(currentitems).find('ul').css('display', 'block');
    var count = $(this).find('.submenu-col li').length;

    if (count >= 20) {
      count = 3;
      $(this).find('ul.submenu-list').first().css('column-rule', '1px solid #6F7C98');
    } else {
      if (count > 10) {
        count = 2;
        $(this).find('ul.submenu-list').first().css('width', '700px');
        $(this).find('ul.submenu-list').first().css('column-rule', '1px solid #8c96ad');
      }
      else {
        $(this).find('ul.submenu-list').first().css('width', '350px');
        count = 1;
      }
    }

    var position = $(this).find('ul').position();
    $(this).find('ul.submenu-list').first().css('columns', 'auto ' + count);

    if (position !== undefined) {
      if (position.left + $(this).find('ul').width() >= $(this).parent().width()) {
        $(this).find('ul').css('right', '0');
      } else $(this).find('ul').css('left', 'inherit');
    }
  }, function() {
    timedelay = setTimeout(function() {
      $(currentitems).find('ul').css('display', 'none');
    }, 150);
  });

  var timedelay_lk = 0;
  $('.account').hover(function() {
    clearTimeout(timedelay_lk);
    $(this).find('ul').css('display', 'block');
  }, function() {
    timedelay_lk = setTimeout(function() {
      $('.account').find('ul').css('display', 'none');
    }, 150);
  });
}
