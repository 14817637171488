import select from './select';
import {btnFloatingMode} from './btnFloating';

class Modal {
  constructor(modalDialog) {
    this._defaultSetting();

    switch (modalDialog) {
      case 'modal-region': this._setFunctionalRegion();break;
      case 'modal-account': this._setFunctionalAccount(); break;
      default: break;
    }
  }

  _defaultSetting() {
    $('[data-close]').on('click', function() {
      $('div.popupModalOverlay').remove();
    });
    select('modal', '');
    const input = document.querySelector('.form-input-modal .search-input-modal');
    if(input) {
      input.addEventListener('keyup',() => {
        this._checkPath(input);
      });
      input.addEventListener('blur',() => {
        this._checkPath(input);
      });
      this._checkPath(input);
    }
    initMCustomScrollbar();
    $(document).mouseup(function(e) {
      if ($('.modal-content').has(e.target).length === 0) {
        if($(e.target).hasClass('modal')) {
          $('div.popupModalOverlay').remove();
        }
      }
    });
  }

  _setFunctionalAccount() {
    $('p.show-hide-info').click(function() {
      $('p.show-hide-info').next().css('display', 'none');
      $(this).text(function() {
        var text;
        if ($(this).text() === 'Скрыть подробности') {
          text = 'Показать подробности';
        }
        else {
          $('p.show-hide-info').text('Показать подробности');
          text = 'Скрыть подробности';
          $(this).next().toggle();
        }

        return text;
      });

    });
  }

  _setFunctionalRegion() {
    if (Cookies.get('selectElementName'))
      $('#region-select .select').text(Cookies.get('selectElementName'));

    $('[data-save]').on('click', function() {
      Cookies.set('selectElementName', $('#region-select .select').text());
      $('.region_name').text(Cookies.get('selectElementName'));
      $('div.popupModalOverlay').remove();
    });
  }

  _checkPath(input) {
    if(input.value.trim()) {
      input.classList.add('valid');
    }else {
      input.classList.remove('valid');
    }
  }
}

export default function(modalValue) {
  new Modal(modalValue);
}
