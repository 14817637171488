import 'slick-carousel';

export default function() {
  $('.slider-news').slick({
    dots: true,
    prevArrow: false,
    nextArrow: false,
    autoplay: true,
    autoplaySpeed: 10000,
  });
  $('.partners-slider').slick({
    slidesToShow: 6,
    dots: false,
    prevArrow: $('.partners-slider__btn_prev'),
    nextArrow: $('.partners-slider__btn_next'),
    autoplay: true,
    autoplaySpeed: 5000,
  });
  var randomSlideId = Math.random() * 4 | 0;
  $('.slider-banner').slick({
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed:5000,
    prevArrow: "<img src='/epz/static/img/icons/left_arrow.svg' class='prev' alt=''>",
    nextArrow: "<img src='/epz/static/img/icons/right_arrow.svg' class='next' alt=''>",
    initialSlide: +randomSlideId,
  });
}
