export default {
  container: 'calendar-container',
  calendar: {
    callCalendar: 'callCalendar',
    calendarInner: 'calendar-inner',
    calendarWrapper: 'calendar-wrapper',
    daysWrapper: 'calendar-days',
    input: 'calendar-input__text',
    inputWrapper: 'calendar-input',
    errorWrapper: 'calendar_error',
    anchor: 'inner-anchor',
    wrapper: 'cal',
    inner: 'cal-inner',
    controls: 'c-control',
    month: 'month',
    year: 'year',
    week: 'week',
    cell: 'cell',
    cellItem: 'cell__item',
    today: 'cell__item_today',
    selected: 'cell_selected',
    selectedTill: 'cell_selected_till',
    range: 'cell_range',
    hover: 'cell_hover',
    hoverRange: 'cell_hover_range',
    randeRadius: {
      topStart: 'cell_range_top_start',
      topEnd: 'cell_range_top_end',
      bottomStart: 'cell_range_bottom_start',
      bottomEnd: 'cell_range_bottom_end',
    },
    activeControlRange: 'c-btn_fill_active',
  }
};
