export default function() {

  //component PriceRange START
  $(document).on('click','.cat .cat-text_small',function() {
    setInputPrice(this);
  });

  $(document).on('click','.selectPrice.select-vars__item', function() {
    $(this).closest('.select-wrap').find('.select select-text').attr('data-initial','');

    let selectedItem = $(this).attr('data-class');
    let container = $(this).closest('.row');
    $(container).find('input.price_from, input.price_to').addClass('d-none').prop('value','');
    $(container).find('input.price_from.'+selectedItem+', input.price_to.'+selectedItem+'').removeClass('d-none');
    $(container).find('.errorMessageValidation').text('').attr('for', $(container).find('input.price_to.'+selectedItem).attr('id')).hide();
  });

  function setInputPrice(selector) {
    let container = $(selector).closest('.row');
    let priceFromInput = $(container).find('.price_from:visible');
    let priceToInput = $(container).find('.price_to:visible');

    priceFromInput.val(priceFromInput.prop('title'));
    priceToInput.val(priceToInput.prop('title'));

    let selectPriceFrom = $(selector).attr('data-start');
    let selectPriceTo = $(selector).attr('data-end');

    if(!!selectPriceFrom) {
      priceFromInput.val(selectPriceFrom);
      priceFromInput.change();
    }
    if(!!selectPriceTo) {
      priceToInput.val(selectPriceTo);
      priceToInput.change();
    }
    if(priceFromInput.is('[data-autonumeric]')) {
      AutoNumeric.getAutoNumericElement('#'+priceFromInput.prop('id')).set(selectPriceFrom);
    }
    if(priceToInput.is('[data-autonumeric]')) {
      AutoNumeric.getAutoNumericElement('#'+priceToInput.prop('id')).set(selectPriceTo);
    }
    container.find('.errorContainer > div.errorMessageValidation').hide();
  }
  //component PriceRange END
};
