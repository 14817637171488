import calendarElements from './utils/elements';
import partsHtml from './utils/parts-html';
import dateObjToString from './utils/date-string';



export default class Input {
  constructor(mode, dateChangeCallback) {
    this.params = {
      allowBackspace: true,
      dateValueFrom: '',
      dateValueTill: '',
      innerElement: null,
      contentWrapper: null,
      inputNodes: [],
      maxLong: 10,
      backspaceCode: 8,
      tabCode: 9,
      ctrlDown: false,
      ctrlKey: 17,
      cmdKey: 91,
      vKey: 86,
      cKey: 67
    };

    this._mode = mode;
    this.dateChangeCallback = dateChangeCallback;

    if (typeof dateChangeCallback !== 'function') {
      console.error('Callback should be a function');
      return;
    }
  }



  init(contentWrapper) {
    this.params.contentWrapper = contentWrapper
      .querySelector(`.${calendarElements.calendar.calendarWrapper}`);

    this.params.innerElement = contentWrapper
      .querySelector(`.${calendarElements.calendar.calendarInner}`);

    this.renderLayout();
  }

  renderLayout() {
    let part;
    switch (true) {
      case this.activeMode.start : {
        part = partsHtml.inputOnStart;
        break;
      }
      case this.activeMode.one : {
        part = partsHtml.inputDefault;
        break;
      }
      case this.activeMode.from && !this.activeMode.till : {
        part = partsHtml.inputFrom;
        break;
      }
      case this.activeMode.till && !this.activeMode.from : {
        part = partsHtml.inputTill;
        break;
      }
      default:
        part = partsHtml.inputFromTill;
        break;
    }
    this._changeInput(part);

    const eInputs = this.params.innerElement
      .querySelectorAll(`.${calendarElements.calendar.input}`);

    [].forEach.call(eInputs, (input) => {
      input.addEventListener('keydown', this._keyDown.bind(this));
      input.addEventListener('keyup', this._keyUp.bind(this));
      input.addEventListener('paste', this._pasteDate.bind(this));
      input.addEventListener('blur', this._unFocus.bind(this));
      input.addEventListener('focus', this._focus.bind(this));
    });
    this.params.inputNodes = eInputs;
  }

  _removeErrorClass = () => {
    this.params.contentWrapper.classList.remove(calendarElements.calendar.errorWrapper);
  };

  _focus = (event) => {
    if (this.activeMode.start) {
      this.activeMode.start = false;
      this.activeMode.from = true;
      this.renderLayout();
    }
    this._toggleZindex(true);
    if (event.target.error) {
      event.target.value = '';
      event.target.error = null;
      this._removeErrorClass();
    }
  };

  _unFocus = (event) => {

    this._toggleZindex();
    const value = event.target.value;
    let isEmptyValue = (value) => {
      return value.length < 1;
    };
    let error;
    if (this._mode.till) {
      if (isEmptyValue(this.params.inputNodes[0].value) && isEmptyValue(this.params.inputNodes[1].value)) {
        return;
      }
      error = (!isEmptyValue(this.params.inputNodes[0].value) && this._formateDateValue(this.params.inputNodes[0].value).error)
              || (!isEmptyValue(this.params.inputNodes[1].value) && this._formateDateValue(this.params.inputNodes[1].value).error);
    } else {
      if (isEmptyValue(value)) {
        return;
      }
      error = this._formateDateValue(value).error;
    }

    if (error || (value.length > 0 && value.length < this.params.maxLong)) {
      event.target.error = true;
      this.params.contentWrapper.classList.add(calendarElements.calendar.errorWrapper);
    } else {
      event.target.error = null;
      this._removeErrorClass();
    }
  };

  _toggleZindex = (state = false) => {
    const block = this.params.contentWrapper
      .querySelector(`.${calendarElements.calendar.calendarInner}`);

    if (!block) {
      return;
    }

    if (state) {
      block.style.zIndex = 999;
    } else {
      block.style.zIndex = 1;
    }

  };

  _pasteDate = (event) => {
    event.preventDefault();
    const { direction } = event.target.dataset;
    let value = '';
    if (typeof event.clipboardData === 'undefined')
      value = window.clipboardData.getData('Text');
    else
      value = event.clipboardData.getData('text/plain');
    value = value.replace(/\./g,'').replace(/^(\d{1,2})(\d{1,2})(\d{4})$/, '$1.$2.$3');
    const parts = value.split('.');
    const invalid = parts.filter(part => isNaN(+part));
    if (parts.length !== 3 || invalid.length > 0) {
      return;
    }
    let {defaultDate, string} = this._formateDateValue(value);
    event.target.value = string;

    this.dateChangeCallback(defaultDate, direction);
  };

  _changeInput = (html) => {
    const prevInput = this.params.innerElement
      .querySelector(`.${calendarElements.calendar.inputWrapper}`);
    if (prevInput) {
      prevInput.remove();
    }
    if (this.params.innerElement) {
      this.params.innerElement.insertAdjacentHTML('afterbegin', html);
    }
  };

  _formateDateValue = value => {
    let error = false;
    let [day, month, year] = value.split('.');

    if(!(day && month && year)) {
      error = true;
    }

    if (day < 1) {
      day = '01';
      error = true;
    }
    if (day > 31) {
      day = '31';
      error = true;
    }
    if (month < 1) {
      month = '01';
      error = true;
    }
    if (month > 12) {
      month = '12';
      error = true;
    }
    if (year < 1970) {
      year = '1970';
      error = true;
    }
    if (year > 2070) {
      year = '2070';
      error = true;
    }
    const date = new Date(year, month - 1, +day, 0, 0, 0);
    return {
      error,
      parts: [day, month, year],
      string: [day, month, year].join('.'),
      defaultDate: date
    };
  };

  _keyUp(event) {
    const value = event.target.value;
    if (event.keyCode === this.params.ctrlKey || event.keyCode === this.params.cmdKey) this.params.ctrlDown = false;
    if(value.length === 0) {
      this.dateChangeCallback(null, `emptyField${event.target.dataset.direction}`);
    }

    if (value.length < this.params.maxLong) {
      return;
    }

    const { direction } = event.target.dataset;
    const {error, defaultDate, string} = this._formateDateValue(value);
    if (error) {
      event.target.value = string;
    }
    this.dateChangeCallback(defaultDate, direction);
    this._rangeFormat(event);
  };

  _rangeFormat(event) {
    const parent = event.target.closest('.calendar-input_two');

    if (parent !== null) {

      const fromInput = parent.firstElementChild.lastElementChild;
      const toInput = parent.lastElementChild.lastElementChild;

      let fromValue = fromInput.value;
      let toValue = toInput.value;

      if (fromValue !== '' && toValue !== '' && fromValue > toValue) {

        fromInput.value = toValue;
        toInput.value = fromValue;
      }
    }
  }

  _keyDown(event) {
    const target = event.target;
    const value = target.value;
    if (event.keyCode === this.params.ctrlKey || event.keyCode === this.params.cmdKey) this.params.ctrlDown = true;
    if (
      !this._allowedKeys(event.keyCode,event.key.charCodeAt(0))
    ) {
      event.preventDefault();
    }

    if (
      (value.length === 2 || value.length === 5)
      &&
      event.keyCode !== this.params.backspaceCode
    ) {
      target.value = target.value + '.';
    }

    if (
      value.length === this.params.maxLong
      &&
      event.keyCode !== this.params.backspaceCode
      &&
      (event.keyCode !== this.params.cKey && this.params.ctrlDown)
    ) {
      event.preventDefault();
    }
  }

  _allowedKeys = (code,charCode) => {
    return (charCode >= 48 && charCode <= 57) ||
      code === this.params.backspaceCode ||
      code === this.params.tabCode ||
      (code >= 37 && code <= 40) ||
      (code === this.params.vKey && this.params.ctrlDown) ||
      (code === this.params.cKey && this.params.ctrlDown)
    ;
  };

  set activeMode(value) {
    this._mode = value;
    this.renderLayout();
  }

  set inputValue(value) {
    const [fromDate, tillDate] = value;
    const mode = Object
      .keys(this.activeMode)
      .filter(key => this.activeMode[key]);

    if (mode.length > 1) {
      if (fromDate && this.params.inputNodes[0]) {
        this.params.inputNodes[0].value = dateObjToString(fromDate);
      }
      if (tillDate && this.params.inputNodes[1]) {
        this.params.inputNodes[1].value = dateObjToString(tillDate);
      }
      if (!tillDate && this.params.inputNodes[1]) {
        this.params.inputNodes[1].value = null;
      }
    } else {
      this.params.inputNodes[0].value = dateObjToString((fromDate || tillDate));
    }
    this._removeErrorClass();
  }

  clearValue = (mode) => {
    if (this.params.inputNodes[0]) {
      this.params.inputNodes[0].value = '';
    }
    if (this.params.inputNodes[1]) {
      this.params.inputNodes[1].value = '';
    }
    this.activeMode = mode;
    this.renderLayout();
  };

  get activeMode() {
    return this._mode;
  }

}
