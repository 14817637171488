export default {
  mainWrapper: `
  <div class="calendar-wrapper">
      <div class="calendar-inner">
        <button type="button" class="c-btn c-btn_icon calendar-input__icon-btn" >
          <img src="/epz/static/img/icons/calendar.svg" alt="" class="calendar-input__icon">
        </button>
      </div>
      <span class="calendar__error-text">
        Введите корректную дату
      </span>
  </div>
  `,
  inputOnStart: `
      <div class="calendar-input">
          <div class="wrapper-input">
              <input
                type="text"
                class="calendar-input__text"
                placeholder="дд.мм.гггг — дд.мм.гггг"
                maxlength="10"
                data-direction="from"
                 >
          </div>
      </div>
  `,
  inputDefault: `
      <div class="calendar-input">
          <div class="wrapper-input">
              <input
                type="text"
                class="calendar-input__text"
                placeholder="дд.мм.гггг"
                maxlength="10"
                data-direction="from"
                 >
          </div>
      </div>
  `,
  inputFrom: `
      <div class="calendar-input calendar-input_one">
          <div class="wrapper-input">
              <span class="label">Все от</span>
              <input
              type="text"
              class="calendar-input__text"
              placeholder="дд.мм.гггг"
              maxlength="10"
              data-direction="from"
              >
          </div>
      </div>
  `,
  inputTill: `
    <div class="calendar-input calendar-input_one">
          <div class="wrapper-input">
              <span class="label">Все до</span>
              <input
              type="text"
              class="calendar-input__text"
              placeholder="дд.мм.гггг"
              maxlength="10"
              data-direction="till"
              >
          </div>
      </div>
  `,
  inputFromTill: `
      <div class="calendar-input calendar-input_two">
          <div class="wrapper-input">
            <span class="label">От</span>
            <input
            type="text"
            class="calendar-input__text"
            placeholder="дд.мм.гггг"
            maxlength="10"
            data-direction="from"
              >
          </div>
          <div class="wrapper-input">
            <span class="mark">-</span>
            <span class="label">До</span>
            <input
            type="text"
            class="calendar-input__text"
            placeholder="дд.мм.гггг"
            maxlength="10"
            data-direction="till"
              >
          </div>
      </div>
  `,
  calendar: `
  <div class="calendar-days" id="calendarDays">
    <div class="calendar-days-controls">
      <button class="c-btn c-btn_fill c-control" data-control-range="from">От</button>
      <button class="c-btn c-btn_fill c-control" data-control-range="till">До</button>
    </div>
    <div class="calendar-days-toggles">
      <div class="togler togler_prev">
        <button class="c-btn c-btn_icon prev-year c-control" data-control-direction="prev" data-control-mode="year">
          <img src="/epz/static/img/icons/arrow-year.svg" alt="">
        </button>
        <button class="c-btn c-btn_icon prev-month c-control" data-control-direction="prev" data-control-mode="month">
          <img src="/epz/static/img/icons/arrow-month.svg" alt="">
        </button>
      </div>
      <div class="calendar-days-toggles__info">
        <span class="month">Июнь</span>
        <span class="year">2018</span>
      </div>
      <div class="togler togler_next">
        <button class="c-btn c-btn_icon next-month c-control" data-control-direction="next" data-control-mode="month">
          <img src="/epz/static/img/icons/arrow-month.svg" alt="">
        </button>
        <button class="c-btn c-btn_icon next-year c-control" data-control-direction="next" data-control-mode="year">
          <img src="/epz/static/img/icons/arrow-year.svg" alt="">
        </button>
      </div>
    </div>
    <div class="calendar-dates">
      <div class="dates-head">
        <div class="inner">
          <div class="row">
            <span class="cell">Пн</span>
            <span class="cell">Вт</span>
            <span class="cell">Ср</span>
            <span class="cell">Чт</span>
            <span class="cell">Пт</span>
            <span class="cell">Сб</span>
            <span class="cell">Вс</span>
          </div>
        </div>
      </div>
      <div class="dates-content">
        <div class="inner inner-anchor">
        </div>
      </div>
    </div>
  </div>
  `,
  controls: `
  <div class="cal-controls">
    <button data-prev="true">prevMonth</button>
    <button data-next="true">nextMonth</button>
  </div>
`
};
