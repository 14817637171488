import {btnFloatingMode} from '../lib/btnFloating';

class Select {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.classes = {
      select: 'select',
      selectListOpen: 'select-vars_open',
      selectList: 'select-vars',
      selectLink: 'select-vars__link',
      selectActive: 'select-vars__item--selected',
      arrowSelectListOpen: 'arrow-select-vars_open',
      input: 'select-vars__item-input',
      scroll: 'mCustomScrollBox'
    };
    this.select = this.wrapper.querySelector('.select');
    this.select_icon = this.wrapper.querySelector('.select-icon');
    this.select_icon_img = this.wrapper.querySelector('.select-icon img');
    this.img = this.wrapper.querySelector('img');
    this.svg = this.wrapper.querySelector('svg');
    this.svgPath = this.wrapper.querySelector('path');
    this.itemImg = this.wrapper.querySelector('.select-vars__item--img');
    this.selectVars = this.wrapper.querySelector('.select-vars');
    this.links = this.selectVars.querySelectorAll('.select-vars__item');
    this.input = this.wrapper.querySelector('.select-vars__item-input');
    this.selectedItem = null;

    if (!this.checkNodeElements()) {
      return;
    }
    this.status = false;
  }

  create() {
    this.select.addEventListener('click', this._openSelect.bind(this));
    this.select_icon.addEventListener('click',this._openSelect.bind(this));
    this._bindElements();
  }

  createTransformTitle() {
    let previousElementSibling = this.wrapper.previousElementSibling;
    if(previousElementSibling && previousElementSibling.tagName === 'LABEL') {
      previousElementSibling.hidden = true;
    }
    let transformTitle = this.wrapper.closest('.block').querySelector('.js-transform-title');
    if(transformTitle) {
      transformTitle.innerText = this.links[0].innerText.trim();
      transformTitle.classList.add('mb-0');
      this.wrapper.hidden = true;
    }
  }

  createOnlyElements() {
    this._bindElements();
  }
  checkNodeElements() {
    if (!this.select) {
      return false;
    }
    if (!this.selectVars) {
      return false;
    }
    if (!this.links) {
      return false;
    }
    return true;
  }

  _bindElements() {
    [].forEach.call(this.links, (link) => {
      if (link.classList.contains('select-vars__item--selected')) {
        this.selectedItem = link;
      }
      if (link.innerText.trim() === this.select.innerText.trim()) {
        if(this.selectedItem !== null) {
          this.selectedItem.classList.remove('select-vars__item--selected');
        }
        const link_label = link.querySelector('label');
        if (link_label) {
          link_label.innerText = link_label.innerText.trim();
        } else {
          link.innerText = link.innerText.trim();
        }
        this.selectedItem = link;
        link.classList.add(this.classes.selectActive);
      }
      link.addEventListener('click', this._selectElement.bind(this));
      link.addEventListener('triggered-click', this._selectElement.bind(this, true));
    });
  }

  _openSelect() {
    if (this.status === true) {
      this._closeSelect();
      return;
    }
    // this.select_icon_img.style.transform = 'scale(1, -1)';
    // this.select_icon_img.src = this.select_icon_img.src.replace('icon_arrow_region--gray','icon_arrow_region');
    // this.selectVars.classList.add(this.classes.selectListOpen);
    $(this.selectVars).removeClass(this.classes.selectListOpen).slideToggle();
    this.select_icon.classList.add(this.classes.arrowSelectListOpen);
    this.status = true;
  }
  _closeSelect() {
    if(this.status) {
      // this.select_icon_img.style.transform = 'scale(-1, 1)';
      // this.select_icon_img.src = this.select_icon_img.src.replace('icon_arrow_region','icon_arrow_region--gray');
      // this.selectVars.classList.remove(this.classes.selectListOpen);
      $(this.selectVars).addClass(this.classes.selectListOpen).slideToggle();
      this.select_icon.classList.remove(this.classes.arrowSelectListOpen);
      this.status = false;
    }
  }

  _selectElement(event, isTriggered) {
    if(event.target.dataset.isnotselected === 'true') {
      this._closeSelect();
      if (!isTriggered) {
        btnFloatingMode('select',this.select);
      }
      return;
    }
    if (event.target !== this.itemImg
        && event.target !== this.svg
        && event.target !== this.svgPath
        && event.target !== this.img
    ) {
      let selected = event.target.innerText;
      this.select.innerText = selected;
      this.select.setAttribute('data-selected-value', selected);
      if(this.selectedItem !== null) {
        this.selectedItem.classList.remove('select-vars__item--selected');
      }
      $(event.target).closest('.select-vars__item').addClass('select-vars__item--selected');
      this.selectedItem = event.target;
      if(this.select.dataset.selectThenEvalFunction !== undefined && this.select.dataset.selectThenEvalFunction) {
        eval(this.select.dataset.selectThenEvalFunction + '(' + selected + ')');
      }
      this._closeSelect();
      if (!isTriggered) {
        btnFloatingMode('select',this.select);
      }
    }
  }
  _hasCustomScrollbar(target) {
    let container = target.closest('.'+ this.classes.scroll);
    let result;
    if(container) {
      result = container.querySelector('.'+target.className);
    }
    return !!result;
  }
}
export default function(arg, arg2) {
  let wrapSelects;
  const selectsOnPage = [];
  if(arg2 !== '' && arg === 'onlyElements') {
    wrapSelects = document.querySelectorAll(arg2);
    [].forEach.call(wrapSelects, (wrapper) => {
      const sel = new Select(wrapper);
      sel.createOnlyElements();
    });
  }else {
    if (arg === 'DOM' && arg2 !== '') {
      wrapSelects = document.querySelectorAll(arg2);
    } else if (arg ==='DOM') {
      wrapSelects = document.querySelectorAll('.select-wrap');
    } else {
      wrapSelects = document.querySelectorAll('.select-wrap-modal');
    }

    if (wrapSelects) {
      [].forEach.call(wrapSelects, (wrapper) => {
        const sel = new Select(wrapper);
        if (sel.links.length === 1 && wrapper.hasAttribute('data-transform-title')) {
          sel.createTransformTitle();
        }else {
          sel.create();
          selectsOnPage.push(sel);
        }
      });
    }

    document.addEventListener('click', (event) => {
      selectsOnPage.forEach((select) => {
        if (event.target !== select.select
          && select.status === true
          && event.target !== select.select_icon
          && event.target !== select.select_icon_img
          && event.target !== select.input
          && event.target !== select.itemImg
          && event.target !== select.svg
          && event.target !== select.svgPath
          && event.target !== select.img
          && !select._hasCustomScrollbar(event.target)
        ) {
          select._closeSelect();
        }
      });
    });
  }
}
