import 'core-js/features/promise';
import dropdown from './lib/dropdown';
import select from './lib/select';
import slider from './lib/slider';
import navigation from './lib/navigation_menu';
import btnOnTop from './lib/btn-on-top';
import tooltip from './lib/tooltip';
import calendar from './lib/custom-date-picker/index';
import smartappbanner from './lib/smartappbanner';
import tabs from './lib/tabs';
import card from './lib/card';
import lots from './lib/lots';
import openModal from './lib/openmodal';
import searchResult from './lib/searchResult';
import extendedSearch from './lib/extendedSearch';
import sliderStatistics from './lib/statistics/sliderStatistics';

//import {$,jQuery} from 'jquery';
// export for others scripts to use
// window.$ = $;
// window.jQuery = jQuery;
window.contecstpatch = contextPath;
window.quarter = (new Date().getMonth() + 1 < 4);

document.addEventListener('DOMContentLoaded', () => {
  smartappbanner();
  navigation();
  slider();

  dropdown();
  btnOnTop();

  tabs();

  openModal();
  card();
  lots();
  select('DOM', '');
  searchResult();
  extendedSearch();
  tooltip();
  calendar();
});

document.addEventListener('tooltipEvent', function(e) {
  tooltip(e.detail.selector);
});

document.addEventListener('quizEvent', function(e) {
  openModal();
});

document.addEventListener('controlResultModalEvent', function(e) {
  openModal('.modal-with-signs');
});

document.addEventListener('overrideSelectEvent', function(e) {
  select('DOM', '');
});

document.addEventListener('overrideSelectModalEvent', function(e) {
  select('', '');
});

document.addEventListener('reinitializeSelectEvent', function(e) {
  if(e.detail) {
    if(e.detail.onlyElements) {
      select('onlyElements', e.detail.selectorDom);
    } else if(e.detail.selectorDom) {
      select('DOM', e.detail.selectorDom);
    } else if(e.detail.overrideAllSelectInDom) {
      select('DOM', '');
    } else if(e.detail.overrideAllSelectInModal) {
      select('', '');
    }
  } else {
    select('DOM', '');
  }
});

document.addEventListener('overrideOpenModalEvent', function(e) {
  if (e.detail) {
    if(e.detail.selectorDom) {
      openModal(e.detail.selectorDom);
    }
  } else {
    openModal();
  }
  tooltip();
});

document.addEventListener('overrideDropdownEvent', function(e) {
  if(e.detail && e.detail.selector) {
    dropdown(e.detail.selector);
  }
});
