import SmartBanner from 'smart-app-banner';

export default function() {
  new SmartBanner({
    daysHidden: 7, // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: -1, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    appStoreLanguage: 'ru', // language code for the App Store (defaults to user's browser language)
    title: 'Мобильное приложение ЕИС',
    author: 'Федеральное казначейство',
    button: 'Смотреть',
    store: {
      ios: 'App Store',
      android: 'Google Play',
      windows: 'Windows store'
    },
    price: {
      ios: 'Бесплатно',
      android: 'Бесплатно',
      windows: 'Бесплатно'
    },
    theme: 'ios',
    icon: '../img/icons/eis-mobile-logo.png'
  });
};
