class Lots {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.classes = {
      lots_title: 'lots-wrap-content__title',
      lotsListOpen: 'lots-vars_open',
      lotsList: 'lots-vars',
      arrowLotsListOpen: 'arrow-lotsList-open'
    };
    this.lots_title = this.wrapper.querySelector('.lots-wrap-content__title');
    this.arrow_lots = this.wrapper.querySelector('.arrow-lots');
    this.arrow_lots_img = this.wrapper.querySelector('.arrow-lots img');
    this.lotsVars = this.wrapper.querySelector('.lots-vars');
    // if (!this.checkNodeElements()) {
    //   return;
    // }

  }

  create() {
    this.lots_title.addEventListener('click', this._toggleLots.bind(this));
    this.lots_title.addEventListener('openLots', this._openLots.bind(this));
    this.lots_title.addEventListener('closeLots', this._closeLots.bind(this));
  }

  checkNodeElements() {
    if (!this.lots_title) {
      return false;
    }
    if (!this.selectVars) {
      return false;
    }
    return true;
  }

  _toggleLots() {
    $(this.lotsVars).toggleClass(this.classes.lotsListOpen).slideToggle();
    if (this.lotsVars.classList.contains(this.classes.lotsListOpen)) {
      this.arrow_lots.style.transform = 'rotate(180deg)';
      this.arrow_lots.classList.add(this.classes.arrowLotsListOpen);
    } else {
      this.arrow_lots.style.transform = 'rotate(0deg)';
      this.arrow_lots.classList.remove(this.classes.arrowLotsListOpen);
    }
  }

  _openLots() {
    this.arrow_lots.style.transform = 'rotate(180deg)';
    this.lotsVars.classList.add(this.classes.lotsListOpen);
    this.lotsVars.style.display = 'block';
  }

  _closeLots() {
    this.arrow_lots.style.transform = 'rotate(0)';
    this.lotsVars.classList.remove(this.classes.lotsListOpen);
    this.lotsVars.style.display = 'none';
  }

}

export default function() {

  const wrapLots = document.querySelectorAll('.lots-wrap');

  if (wrapLots) {
    [].forEach.call(wrapLots, (wrapper) => {
      new Lots(wrapper).create();
    });
  }

  $('label[for="checkbox-open-lots"]').click(function() {
    if (!document.getElementById('checkbox-open-lots').checked) {
      if (wrapLots) {
        [].forEach.call(wrapLots, (wrapper) => {
          if (!wrapper.querySelector('.lots-vars').classList.contains('lots-vars_open')) {
            wrapper.querySelector('.lots-wrap-content__title').click();
          }
        });
      }
    } else {
      if (wrapLots) {
        [].forEach.call(wrapLots, (wrapper) => {
          if (wrapper.querySelector('.lots-vars').classList.contains('lots-vars_open')) {
            wrapper.querySelector('.lots-wrap-content__title').click();
          }
        });
      }
    }
  });
}
