

export default (date) => {
  const newDate = new Date(date);
  if (newDate) {
    let day;
    let month;
    let year;

    day = newDate.getDate();
    month = newDate.getMonth() + 1;
    year = newDate.getFullYear();

    if (month && month.toString().length < 2) {
      month = `0${month}`;
    }
    if (day && day.toString().length < 2) {
      day = `0${day}`;
    }
    return `${day}.${month}.${year}`;
  }
  return null;
};
